// custom textarea form object
// used for multiple lines of input

import React from 'react';
import styled from 'styled-components';

export default function Textarea(props) {
	const {label, name, required, className, id} = props;

	return (
		<StyledTextarea className={className}>
			{!!label && (
				<label htmlFor={id}>
					{label}
					{!required && <span> (optional)</span>}
				</label>
			)}
			<textarea id={id} name={name} rows={8} cols={50} />
		</StyledTextarea>
	);
}

const StyledTextarea = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 30px;

	& label {
		color: #192851;
		font-size: 14px;
		font-weight: 700;
		line-height: 25px;
		margin-bottom: 5px;

		span {
			color: #8b8b8b;
			font-weight: 400 !important;
		}
	}

	& textarea {
		resize: none;
		border-radius: 19px;
		border: 1px solid #e2eaf0;
		background-color: white;
		box-sizing: border-box;
		padding: 14px;
		width: 100%;

		&:focus {
			outline: none;
			border-color: #00bdff;
			box-shadow: inset 0 0px 2px #00bdff;
		}

		&:hover {
			border-color: #c8d4dd;
			box-shadow: inset 0 0px 2px #c8d4dd;
		}
	}
`;
