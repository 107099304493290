import React from 'react';
import styled from 'styled-components';

export default function Input(props) {
	const {label, type, name, required, isError, errorMessage, className, id} = props;

	return (
		<StyledInput isError={isError} className={className}>
			{!!label && <label htmlFor={id}>{label}</label>}
			<input type={type} name={name} required={required} />
			{!!isError && !!errorMessage && <p className="error-message">{errorMessage}</p>}
		</StyledInput>
	);
}

const StyledInput = styled.div`
	display: flex;
	flex-direction: column;

	& label {
		font-size: 14px;
		color: #192851;
		font-weight: 700;
		line-height: 1.79;
	}

	& input {
		background-color: #ffffff;
		border-radius: 25px;
		padding: 12.5px 26px;
		border: ${(props) => (props.isError ? '1px solid #eb406d' : '1px solid #e2eaf0')};
		${(props) => (props.isError ? 'box-shadow: inset 0 0px 2px #eb406d' : undefined)}
		&:hover {
			border-color: #c8d4dd;
			box-shadow: inset 0 0px 2px #c8d4dd;
		}

		&:focus {
			outline: none;
			border-color: #00bdff;
			box-shadow: inset 0 0px 2px #00bdff;
		}
	}

	& .error-message {
		color: #eb406d;
		text-align: center;
		font-size: 12px;
		line-height: 25px;
		margin-top: 2px;
		height: 25px;
	}
`;
