// html select dropdown
// options format:
// {
//      value: *,
//      label: string,
// }

import React from 'react';
import styled from 'styled-components';
import selectIcon from '../../images/select-icon.svg';

export default function Select(props) {
	const {name, id, label, options, className, required} = props;

	return (
		<StyledSelect className={`form-select ${className || ''}`}>
			{!!label && (
				<label htmlFor={id}>
					{label}
					{!required && <span> (optional)</span>}
				</label>
			)}
			<div className="select-wrapper">
				<select name={name} id={id}>
					{options.map((option) => (
						<option key={option.value} value={option.value}>
							{option.label || option.value}
						</option>
					))}
				</select>
				<img src={selectIcon} alt="Select" className="select-icon" />
			</div>
		</StyledSelect>
	);
}

const StyledSelect = styled.div`
	display: flex;
	flex-direction: column;

	& label {
		font-size: 14px;
		color: #192851;
		font-weight: 700;
		line-height: 1.79;
		margin-bottom: 5px;

		span {
			color: #8b8b8b;
			font-weight: 400 !important;
		}
	}

	& .select-wrapper {
		position: relative;
		display: block;
		& select {
			background-color: #ffffff;
			border-radius: 25px;
			padding: 12.5px 26px;
			border: 1px solid #e2eaf0;
			appearance: none;
			width: 100%;

			&:focus {
				outline: none;
				border-color: #00bdff;
				box-shadow: inset 0 0px 2px #00bdff;
			}
			&:hover {
				border-color: #c8d4dd;
				box-shadow: inset 0 0px 2px #c8d4dd;
			}
		}
		& .select-icon {
			position: absolute;
			top: 50%;
			right: 25px;
			transform: translatey(-50%);
		}
	}
`;
