import React from 'react';
import styled from 'styled-components';

import PageBase from '../components/PageBase';
import Container from '../components/Container';
import Input from '../components/Forms/Input';
import Radio from '../components/Forms/Radio';
import Label from '../components/Forms/Label';
import Textarea from '../components/Forms/Textarea';
import Select from '../components/Forms/Select';
import Datepicker from '../components/Forms/Datepicker';
import Submit from '../components/Forms/Submit';

export default function Contact() {
	const [selectedRadio, setSelectedRadio] = React.useState('');

	const customSelectRadio = (target) => {
		if (target.checked) {
			setSelectedRadio(target.value);
		}
	};

	const handleFormSubmit = async (e) => {
		e.preventDefault();

		// reset messages
		document.getElementById('success').style.display = 'none';
		document.getElementById('required').style.display = 'none';
		document.getElementById('failure').style.display = 'none';

		// extract form data
		const formdata = new FormData(e.target);

		// convert FormData to json object
		const json = {};
		formdata.forEach(function (value, prop) {
			json[prop] = value;
		});

		// convert json to urlencoded query string
		const formBody = Object.keys(json)
			.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`)
			.join('&');

		// POST the request to php mail
		await fetch('/backend/contact-form.php', {
			method: 'POST',
			headers: {'Content-Type': 'application/x-www-form-urlencoded'},
			body: formBody
		})
			.then((response) => {
				const {status} = response;
				if (status === 200) {
					// reset form
					document.getElementById('contact-form').reset();
					// display success message
					document.getElementById('success').style.display = 'block';
				} else if (status === 400) {
					// display error message
					document.getElementById('required').style.display = 'block';
				} else {
					// display error message
					document.getElementById('failure').style.display = 'block';
				}
			})
			.catch(() => {
				// display error message
				document.getElementById('failure').style.display = 'block';
			});
	};

	return (
		<PageBase title="Contact">
			<StyledContactPage>
				<Container>
					<StyledFirstSection>
						<h3>Get in touch</h3>
						<p>
							If you are a Financial Planning Professional looking for a new AFSL or interested in knowing more about our service offerings,
							please contact us for further information about how we can help you.
						</p>

						<ul className="company-info">
							<li>
								<span>NEO Financial Solutions Pty Ltd</span>
							</li>
							<li>
								AFSL 385 845 | ABN &nbsp;
								{/* eslint-disable-next-line */}
								<a href="#" className="abn-num">
									64 141 607 098
								</a>
							</li>
							<li>Level 5, 432 Murray Street, Perth WA 6000</li>
						</ul>
						<ul>
							<li>You can also give us a call on</li>
							<li>
								<a href="tel:+61894447494">(08) 9444 7494</a>
							</li>
						</ul>
						<ul>
							<li>Send us email directly on</li>
							<li>
								<a href="mailto:admin@neofs.com.au">admin@neofs.com.au</a>
							</li>
						</ul>
					</StyledFirstSection>
					<StyledFormSection>
						<h3>Send message</h3>
						<form onSubmit={handleFormSubmit} action="" method="post" id="contact-form">
							<Input
								className="form-input"
								id="first-name-last-name"
								name="first-name-last-name"
								label="Your full name and surname"
								type="text"
								required
							/>
							<Input className="form-input" id="email" name="email" label="Email" type="email" required />
							<Input className="form-input" id="contact-number" name="contact-number" label="Contact number" type="tel" />
							<Label className="form-input" value="What it's about?" />
							<Radio
								name="what-its-about"
								label="NEO's Service Offering"
								value="NEO's Service Offering"
								id="i-am-seeking-financial-advice"
							/>
							<Radio
								name="what-its-about"
								label="Becoming an Authorised Representative"
								value="Becoming an Authorised Representative"
								id="i-wish-to-become-a-neo-adviser"
							/>
							<Textarea className="form-input" name="more-details" label="Would you like to add more details?" id="more-details" />
							<Label className="form-input" value="Contact details" />
							<Radio
								name="when-to-contact"
								label="Contact me as soon as possible"
								value="contact-me-as-soon-as-possible"
								id="contact-me-as-soon-as-possible"
								onClick={(e) => customSelectRadio(e.target)}
							/>
							<Radio
								name="when-to-contact"
								label="I would like to select how and when to contact me"
								value="i-would-like-to-select-how-and-when-to-contact-me"
								id="custom-time"
								onClick={(e) => customSelectRadio(e.target)}
							/>
							{selectedRadio === 'i-would-like-to-select-how-and-when-to-contact-me' && (
								<div>
									<Select
										label="How would you like to be contacted?"
										name="how-to-contact"
										options={[
											{
												value: 'email',
												label: 'Via email'
											},
											{
												value: 'phone',
												label: 'Via phone'
											}
										]}
									/>
									<Datepicker name="time-to-contact" label="Would you like to contated on a specific date? " />
									<Select
										label="Preferred Day for us to contact you?"
										name="preferred-day-for-us-to-contact-you"
										options={[
											{
												value: 'monday',
												label: 'Monday'
											},
											{
												value: 'tuesday',
												label: 'Tuesday'
											},
											{
												value: 'wednesday',
												label: 'Wednesday'
											},
											{
												value: 'thursday',
												label: 'Thursday'
											},
											{
												value: 'friday',
												label: 'Friday'
											},
											{
												value: 'saturday',
												label: 'Saturday'
											},
											{
												value: 'sunday',
												label: 'Sunday'
											}
										]}
									/>
									<Select
										label="Preferred time for us to contact you?"
										name="preferred-time-for-us-to-contact-you"
										options={[
											{
												value: '00 AM'
											},
											{
												value: '01 AM'
											},
											{
												value: '02 AM'
											},
											{
												value: '03 AM'
											},
											{
												value: '04 AM'
											},
											{
												value: '05 AM'
											},
											{
												value: '06 AM'
											},
											{
												value: '07 AM'
											},
											{
												value: '08 AM'
											},
											{
												value: '09 AM'
											},
											{
												value: '10 AM'
											},
											{
												value: '11 AM'
											},
											{
												value: '12 AM'
											},
											{
												value: '01 PM'
											},
											{
												value: '02 PM'
											},
											{
												value: '03 PM'
											},
											{
												value: '04 PM'
											},
											{
												value: '05 PM'
											},
											{
												value: '06 PM'
											},
											{
												value: '07 PM'
											},
											{
												value: '08 PM'
											},
											{
												value: '09 PM'
											},
											{
												value: '10 PM'
											},
											{
												value: '11 PM'
											},
											{
												value: '12 PM'
											}
										]}
									/>
								</div>
							)}

							<Submit />
							<div id="success">
								<h5>Message sent.</h5>
								<p>Your message has been successfully sent.</p>
								<p>We will get back to you shortly. Thank you.</p>
							</div>
							<div id="required">Name, email and contact number are required.</div>
							<div id="failure">Something went wrong! Please try later or contact us directly.</div>
						</form>
					</StyledFormSection>
				</Container>
			</StyledContactPage>
		</PageBase>
	);
}

const StyledContactPage = styled.div`
	background-color: #f8f9fb;
	@media screen and (min-width: 768px) {
		display: flex;
		flex-direction: row;
	}
`;

const StyledFirstSection = styled.section`
	padding: 25px 0;

	& .abn-num {
		pointer-events: none;
		font-size: 13px;
		line-height: 22px;
		color: #49667b;
		display: inline-block;
		font-weight: 400;
	}

	h3 {
		font-size: 32px;
		color: #192851;
		font-weight: 700;
		text-align: center;
	}

	h5 {
		margin-top: 50px;
		color: #192851;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 16px;
	}

	& .what-its-about-list {
		margin: 15px 0 30px 0;
		li {
			color: #49667b;
			line-height: 25px;
			font-size: 15px;
			font-weight: 400;
		}
	}

	p {
		color: #49667b;
		font-size: 14px;
		text-align: center;
		line-height: 25px;
		margin-top: 20px;
	}

	& .company-info {
		li {
			font-size: 13px;
			line-height: 22px;

			span {
				font-size: 15px;
			}
		}
	}

	ul {
		list-style: none;
		margin-top: 30px;

		li {
			text-align: center;
			font-size: 13px;
			color: #49667b;

			span {
				font-weight: 700;
				font-size: 15px;
			}

			a {
				color: #192851;
				font-size: 22px;
				font-weight: 800;
				text-decoration: none;
				margin-top: 15px;
				display: block;
				margin-top: 15px;

				&:hover,
				&:focus {
					color: #192851;
					opacity: 0.8;
				}
			}
		}
	}

	@media screen and (min-width: 768px) {
		width: 50%;
		padding: 50px 0;
		display: flex;
		flex-direction: column;

		h3 {
			font-size: 46px;
			text-align: left;
		}

		p {
			width: 80%;
			text-align: left;
		}

		ul > li {
			text-align: left;
		}
	}
`;

const StyledFormSection = styled.section`
	padding: 25px 0;
	background-color: #f8f9fb;

	label {
		margin-top: 20px;
	}

	h3 {
		text-align: center;
		color: #823fd3;
		font-size: 31px;
		font-weight: 700;
		margin-bottom: 30px;
	}

	& .input {
		display: flex;
		flex-direction: column;
	}

	input[type='submit'] {
		margin-bottom: 30px;
		-webkit-appearance: none;
		outline: none !important;
	}

	#success {
		max-width: 80%;
		display: none;

		h5 {
			color: green;
			font-size: 20px;
			font-weight: 700;
			padding-bottom: 10px;
		}

		p {
			font-size: 15px;
			color: #49667b;
			padding-bottom: 12px;
			line-height: 22px;
		}

		a {
			color: green;
			text-decoration: none;
		}
	}

	#required {
		color: red;
		display: none;
	}

	#failure {
		color: red;
		display: none;
	}

	@media screen and (min-width: 768px) {
		width: 50%;
		padding: 50px 0;

		h3 {
			font-size: 46px;
			text-align: left;
		}

		& .form-input,
		& .form-select,
		& .form-datepicker {
			width: 70%;
		}
	}
`;
