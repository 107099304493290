// custom "label" -> used when you want to add a label to a group of inputs
// eg. radio group

import React from 'react';
import styled from 'styled-components';

export default function Label(props) {
	const {value, className} = props;

	return <StyledLabel className={className}>{value}</StyledLabel>;
}

const StyledLabel = styled.p`
	font-size: 14px;
	color: #192851;
	font-weight: 700;
	margin: 20px 0;
`;
