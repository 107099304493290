// default HTML datepicker with custom styling

import React from 'react';
import styled from 'styled-components';
import calendarIcon from '../../images/calendar.svg';

export default function Datepicker(props) {
	const {id, label, name, className, required, value, min, max} = props;

	return (
		<StyledDatepicker className={`form-datepicker ${className || ''}`}>
			{!!label && (
				<label htmlFor={id}>
					{label}
					{!required && <span> (optional)</span>}
				</label>
			)}
			<div className="datepicker-wrapper">
				<input type="date" name={name} id={id} value={value} min={min} max={max} />
				<img src={calendarIcon} alt="calendar" className="datepicker-icon" />
			</div>
		</StyledDatepicker>
	);
}

const StyledDatepicker = styled.div`
	display: flex;
	flex-direction: column;

	& label {
		font-size: 14px;
		color: #192851;
		font-weight: 700;
		line-height: 1.79;
		margin-bottom: 5px;

		span {
			color: #8b8b8b;
			font-weight: 400 !important;
		}
	}

	& .datepicker-wrapper {
		position: relative;
		width: 100%;
		display: flex;
		flex-grow: 1;

		& input {
			background-color: #ffffff;
			border-radius: 25px;
			padding: 12.5px 26px;
			border: 1px solid #e2eaf0;
			appearance: none;
			width: 100%;

			&:focus {
				outline: none;
				border-color: #00bdff;
				box-shadow: inset 0 0px 2px #00bdff;
			}
			&:hover {
				border-color: #c8d4dd;
				box-shadow: inset 0 0px 2px #c8d4dd;
			}
		}

		& input::-webkit-calendar-picker-indicator {
			background: transparent;
			bottom: 0;
			color: transparent;
			cursor: pointer;
			height: auto;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			width: auto;
		}

		& .datepicker-icon {
			position: absolute;
			top: 50%;
			transform: translatey(-50%);
			right: 25px;
			z-index: 0;
		}
	}
`;
