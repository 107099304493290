// form submit button

import React from 'react';
import styled from 'styled-components';

export default function Submit() {
	return <StyledSubmit className="submit-btn" type="submit" value="Submit message" />;
}

const StyledSubmit = styled.input`
	background-color: #192851;
	font-size: 15px;
	font-weight: 700;
	color: white;
	border-radius: 100px;
	border: none;
	width: 272px;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 30px;
	cursor: pointer;
`;
