// custom select component

import React from 'react';
import styled from 'styled-components';

export default function Radio(props) {
	const {id, label, value, name, checked, className, onClick} = props;

	return (
		<StyledRadio className={className} onClick={onClick}>
			<div className="wrapper">
				<input type="radio" checked={checked} name={name} value={value} id={id} />
				<div className="indicator">
					<div className="indicator-bg" />
				</div>
				{!!label && <label htmlFor={id}>{label}</label>}
			</div>
		</StyledRadio>
	);
}

const StyledRadio = styled.div`

	margin-top: 15px;

	& .wrapper {
		position: relative;
		display: flex;
		align-items: center;

		label {
			color: #192851;
			margin-top: 0 !important;
			font-size: 14px;
			line-height: 25px;
			margin-left: 15px;
		}

		& input {
			width: 25px;
			height: 25px;
			z-index: 3;
			opacity: 0;
		}

		& .indicator {
			width: 25px;
			height: 25px;
			position: absolute;
			border: 1px solid #9eb8cb;
			background-color: white;
			border-radius: 100%;
			left: -1.5px;
			display: flex;
			justify-content: center;
			align-items: center;

			&-bg{
				width 12.5px;
				height: 12.5px;
				background-color: #192851;
				border-radius: 100%;
				opacity: 0;
			}
		}

		& input[type='radio']:checked ~ .indicator > .indicator-bg {
			opacity: 1;
		}
	}
`;
